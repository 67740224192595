import { useRouter } from 'next/router';
import React from 'react';
import { FiPlus } from 'react-icons/fi';

import useOwner from 'lib/contexts/OwnerContext';
import useProjects from 'lib/hooks/projects/useProjects';
import { NextPageWithLayout } from 'lib/next';

import Projects from 'components/interfaces/Dashboard/Projects';
import DashboardLayout from 'components/layouts/DashboardLayout/DashboardLayout';
import { Button } from 'components/ui/Button';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import Error from 'components/ui/States/Error';
import { Heading } from 'components/ui/Typography';

const Dashboard: NextPageWithLayout = () => {
  const router = useRouter();
  const { owner: ownerFromUrl } = router.query as { owner: string };
  const { owner } = useOwner();

  if (ownerFromUrl && ownerFromUrl !== owner) {
    router.replace(`/${owner}`);
  }

  const { data: projects, isLoading: projectLoading, isError: projectError } = useProjects(owner);
  const onProjectClick = (projectName: string) => {
    router.push(`/${owner}/${projectName}`);
  };

  const handleRedirect = () => {
    router.push(`/${owner}/new`);
  };

  return (
    <div className="h-full space-y-12 flex flex-col">
      <div className="space-y-4">
        <Heading variant={'h1'} className={'text-primary-white'}>
          Unweave dashboard
        </Heading>
        <Button type={'primary'} variant={'action'} icon={FiPlus} onClick={handleRedirect}>
          Create new project
        </Button>
      </div>
      {projectLoading && <LoadingSpinner />}
      {projectError && <Error />}
      {projects && (
        <>
          {
            <Projects
              title={`All Projects • ${projects.length}`}
              projects={projects}
              onProjectClick={onProjectClick}
            />
          }
        </>
      )}
    </div>
  );
};

Dashboard.getLayout = (page) => {
  return <DashboardLayout>{page}</DashboardLayout>;
};
export default Dashboard;
