const TIME_BREAKPOINT = 12 * 60 * 60 * 1000;

function timeDifference(current: number, previous: number) {
  const milliSecondsPerMinute = 60 * 1000;
  const milliSecondsPerHour = milliSecondsPerMinute * 60;
  const milliSecondsPerDay = milliSecondsPerHour * 24;
  const milliSecondsPerMonth = milliSecondsPerDay * 30;
  const milliSecondsPerYear = milliSecondsPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < milliSecondsPerMinute / 3) {
    return 'just now';
  }

  if (elapsed < milliSecondsPerMinute) {
    return 'less than 1 minute ago';
  }
  if (elapsed < milliSecondsPerHour) {
    return `${Math.round(elapsed / milliSecondsPerMinute)} minute(s) ago`;
  }
  if (elapsed < milliSecondsPerDay) {
    return `${Math.round(elapsed / milliSecondsPerHour)} hour(s) ago`;
  }
  if (elapsed < milliSecondsPerMonth) {
    const days = Math.round(elapsed / milliSecondsPerDay);
    return days === 1 ? `1 day ago` : `${days} day(s) ago`;
  }
  if (elapsed < milliSecondsPerYear) {
    return `${Math.round(elapsed / milliSecondsPerMonth)} month(s) ago`;
  }
  return `${Math.round(elapsed / milliSecondsPerYear)} year(s) ago`;
}

export default function useDate(date: Date) {
  const timeElapsed = Date.now() - date.getTime();
  if (timeElapsed > TIME_BREAKPOINT) {
    return date.toDateString();
  }
  return timeDifference(Date.now(), date.getTime());
}
