import Link from 'next/link';
import { useRouter } from 'next/router';
import { posthog } from 'posthog-js';
import { FC, useEffect, useState } from 'react';

import useUser from 'lib/contexts/UserProvider';
import useOrganizations from 'lib/hooks/account/useOrganizations';

import useOwner from '../../lib/contexts/OwnerContext';
import { Text } from './Typography';

interface Props {
  crumbs?: { label: string; href: string }[];
}

const Breadcrumbs: FC<Props> = ({ crumbs = [] }) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [items, setItems] = useState([] as { label: string; href: string }[]);

  const user = useUser();
  const router = useRouter();
  const { owner } = useOwner();

  const { data: orgs } = useOrganizations({ initialData: [], enabled: isFeatureEnabled });

  useEffect(() => {
    if (crumbs?.length) {
      setItems(crumbs);
      return;
    }
    // deconstruct the path into an array of strings
    const path = router.asPath
      .split('/')
      .filter((p) => p !== '')
      .filter((p) => !orgs?.map((o) => o.name).includes(p))
      .filter((p) => p !== user?.github_username)
      .filter((p) => !p.includes('?'));

    // create an array of objects with the label and href
    const items = path.map((p, i) => {
      const href = `/${owner}/${path.slice(0, i + 1).join('/')}`;
      return { label: p, href };
    });
    setItems(items);
  }, [router.asPath, user]);

  useEffect(() => {
    // TODO: remove this once we have a better way to reload feature flags
    posthog.reloadFeatureFlags();
    posthog.onFeatureFlags(() => {
      if (posthog.isFeatureEnabled('organizations')) {
        setIsFeatureEnabled(true);
      }
    });
  }, []);

  return (
    <>
      <div className="flex items-center justify-center space-x-2">
        <Link href={`/${owner}`}>
          <Text
            variant="subtitle"
            className="text-system-grey5 hover:text-system-grey3 transition-colors"
          >
            {owner}
          </Text>
        </Link>
        {items.map((item) => (
          <Link key={item.href} href={item.href}>
            <Text
              variant="subtitle"
              className="text-system-grey5 hover:text-system-grey3 transition-colors"
            >
              / {item.label}
            </Text>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Breadcrumbs;
