import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { supabase } from 'lib/supabase';
import { Exec } from 'lib/types';

const getSessions = async (project_id?: string): Promise<Exec[]> => {
  const { data, error } = await supabase
    .from('sessions')
    .select('*')
    .eq('project_id', project_id)
    .returns<Exec>();

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error('Sessions not found');
  }

  return data;
};

export default function useSessions(project_id: string, options?: UseQueryOptions) {
  return useQuery({
    queryKey: [project_id],
    queryFn: () => getSessions(project_id),
    ...options,
  }) as UseQueryResult<Exec[]>;
}
