import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';

import { supabase } from 'lib/supabase';
import type { Organization } from 'lib/types';

const getOrganizations = async (): Promise<Organization[]> => {
  const { data, error } = await supabase.from('organizations').select('*');

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error('Organizations not found');
  }

  return data;
};

export default function useOrganizations(options?: UseQueryOptions) {
  return useQuery({
    queryKey: ['organizations'],
    queryFn: () => getOrganizations(),
    ...options,
  }) as UseQueryResult<Organization[]>;
}
