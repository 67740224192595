import React from 'react';

import type { Project } from 'lib/types';

import { Text } from 'components/ui/Typography';

import ProjectCard from './ProjectCard';

type Props = {
  title?: string;
  projects: Partial<Project[]>;
  onProjectClick: (projectID: string) => void;
};

export default function ProjectsPanel({ title, projects, onProjectClick }: Props) {
  const sortedProjects = projects.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  return (
    <div className="space-y-3">
      {title && (
        <Text variant={'subtitle'} className={'text-system-grey5 px-3'}>
          {title}
        </Text>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3">
        {sortedProjects.map((project: Project, idx: number) => (
          <ProjectCard key={idx} project={project} onClick={() => onProjectClick(project.name)} />
        ))}
      </div>
    </div>
  );
}
