import React from 'react';

import Layout from 'components/layouts/Layout';
import Breadcrumbs from 'components/ui/Breadcrumbs';
import { ScrollablePanel } from 'components/ui/Panel';

type Props = {};

export default function DashboardLayout({ children }: React.PropsWithChildren<Props>) {
  return (
    <Layout>
      <Layout.NavigationBar breadcrumbs={<Breadcrumbs />} />
      <Layout.Body>
        <div className="flex flex-grow relative h-full">
          <ScrollablePanel className={'w-full'}>{children}</ScrollablePanel>
        </div>
      </Layout.Body>
    </Layout>
  );
}
