import React, { useMemo } from 'react';

import useUser from 'lib/contexts/UserProvider';
import useSessions from 'lib/hooks/execs/useSessions';
import useDate from 'lib/hooks/useDate';
import type { Exec, Project } from 'lib/types';

import { Heading, Text } from 'components/ui/Typography';

type Props = {
  onClick: () => void;
  project: Project;
};

export default function ProjectCard({ onClick, project }: Props) {
  const { isLoading, isError, isSuccess, data } = useSessions(project.id);

  const user = useUser();

  const activeSessions = useMemo(
    () => (data ? data.filter((session: Exec) => session.status === 'running') : []),
    [data]
  );

  const initializingSessions = useMemo(
    () => (data ? data.filter((session: Exec) => session.status === 'initializing') : []),
    [data]
  );

  const terminatedSessions = useMemo(
    () => (data ? data.filter((session: Exec) => session.status === 'terminated') : []),
    [data]
  );

  return (
    <div
      onClick={onClick}
      className={`rounded-lg border border-system-grey6 transition-colors flex-shrink-0 ${''} cursor-pointer px-4 py-4 bg-system-grey7 hover:bg-primary-black`}
    >
      <div className="flex flex-col">
        <div className="w-full flex justify-between">
          <div className="flex justify-center items-center w-8 h-8">
            {project.icon && <Heading variant="h3">{project.icon}</Heading>}
          </div>
          <Text variant={'subtitle'} className="text-system-grey3 ml-2">
            <span className="text-system-grey5">created</span>{' '}
            {useDate(new Date(project.createdAt))}
          </Text>
        </div>
        <Heading variant={'title'} className="text-system-grey3 mt-5">
          {user.github_username ?? ''} /
        </Heading>
        <Heading variant={'h4'} className="text-primary-white">
          {project.name}
        </Heading>
        <Text variant={'subtitle'} className="mt-3 flex space-x-3">
          {activeSessions.length ? (
            <span className={`text-primary-green ${!activeSessions.length ? 'opacity-50' : ''}`}>
              • {`${activeSessions.length}`} active
            </span>
          ) : null}
          {initializingSessions.length ? (
            <span
              className={`text-primary-yellow ${!initializingSessions.length ? 'opacity-50' : ''}`}
            >
              • {`${initializingSessions.length}`} initializing
            </span>
          ) : null}
        </Text>
      </div>
    </div>
  );
}
